import TypeAhead from '@bc/algolia-plp/header/type-ahead'
import { ALGOLIA_QUERY_LENGTH_LIMIT } from '@constants/algolia-plp/configure'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import Image from '@gc/ui-components/image'
import { useGlobalHeaderIconsAndTextData } from '@hooks/global/new-header'
import { useResponsiveBreakpoint } from '@hooks/global/useResponsiveBreakpoint'
import { useDebounce, useLocalStorageState } from 'ahooks'
import classnames from 'classnames'
import { filter, slice, trim } from 'lodash'
import { useEffect, useState } from 'react'

interface SearchInputProps {
  isOpenSearch: boolean
  showSearch: (show: boolean) => void
  classes?: string
}
const SearchInput: React.FC<SearchInputProps> = ({
  classes,
  isOpenSearch,
  showSearch
}) => {
  const { isBiggerThanLegacy } = useResponsiveBreakpoint()
  const { data: HeaderIconsAndText } = useGlobalHeaderIconsAndTextData()
  const {
    imgSrc = '/assets/icons/header/Search.svg',
    altText = 'Header Search icon',
    placeHolder = 'Find your sound'
  } = HeaderIconsAndText?.search ?? {}
  const [placeHolderContent, setPlaceHolderContent] =
    useState<string>(placeHolder)
  const [isFocusSearchInput, setIsFocusSearchInput] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [recentSearchValues = [], setRecentSearchValues] = useLocalStorageState<
    string[] | undefined
  >('recentSearchValues')

  const debouncedSearchValue = useDebounce(searchValue, { wait: 500 })

  const handleSearch = () => {
    const arr = filter(recentSearchValues, item => item !== searchValue)
    arr.unshift(searchValue)
    setRecentSearchValues(slice(arr, 0, 10))
    const encodeValue = encodeURIComponent(trim(searchValue))
    const path = `/search?typeAheadSuggestion=true&fromRecentHistory=false&Ntt=${encodeValue}`
    window.location.href = path
  }
  useEffect(() => {
    setSearchValue(sessionStorage.getItem('searchQuery') ?? '')
  }, [])

  return (
    <div
      className={classnames(
        'header-search-wrapper h-full px-2 legacy:!px-0 items-center legacy:flex-1 flex',
        classes
      )}
    >
      <div
        className={classnames('mask', {
          'bg-[rgba(0,0,0,0.6)] legacy:!bg-[rgba(0,0,0,0.8)] fixed w-full h-full top-0 left-0 z-[1001]':
            isOpenSearch
        })}
        onClick={() => {
          showSearch(false)
          setIsFocusSearchInput(false)
        }}
      />
      <div
        className={classnames('h-[38px] legacy:h-[52px] w-full relative', {
          'z-[1002]': isOpenSearch
        })}
      >
        <label htmlFor="header-search-input" className="invisible absolute">
          search
        </label>
        <input
          type="text"
          maxLength={ALGOLIA_QUERY_LENGTH_LIMIT}
          id="header-search-input"
          className="border outline-[0px] h-full w-full text-sm legacy:text-base leading-4 !border-transparent bg-[#F5F5F5] rounded pl-2 legacy:!pl-4 placeholder:text-[#333] placeholder:font-medium pr-[72px]"
          placeholder={placeHolderContent}
          value={searchValue}
          autoComplete="off"
          onChange={e => {
            if (e?.target?.value === '') {
              setSearchValue(' ')
            } else {
              setSearchValue(e?.target?.value)
            }
          }}
          onKeyUp={e => {
            if (e.key === 'Enter' && searchValue) {
              handleSearch()
            }
          }}
          onFocus={() => {
            setPlaceHolderContent('')
            showSearch(true)
            setIsFocusSearchInput(true)
          }}
          onBlur={() => {
            setPlaceHolderContent(placeHolder)
          }}
        />
        <button
          className="absolute right-0 top-0 w-[72px] h-full flex items-center justify-center cursor-pointer"
          onClick={() => {
            if (searchValue) {
              handleSearch()
            }
          }}
        >
          <span className="hidden">search</span>
          <Image
            src={imgSrc}
            height={isBiggerThanLegacy ? 28 : 20}
            width={isBiggerThanLegacy ? 28 : 20}
            alt={altText}
          />
        </button>
        {isFocusSearchInput ? (
          <TypeAhead
            debouncedSearchValue={debouncedSearchValue}
            recentSearchValues={recentSearchValues || []}
            changeRecentSearchValues={setRecentSearchValues}
          />
        ) : null}
      </div>
    </div>
  )
}

export default withErrorBoundary(SearchInput)
