import type { INewSubNavigation } from '@gc/common-lib/api/header'
import { useResponsive } from 'ahooks'
import classNames from 'classnames'
import { map } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

const MENU_ITEM_MARGIN_FULL_SCREEN = 32
const MENU_ITEM_MARGIN_SMALLER_SCREEN = 16
const MORE_BUTTON_WIDTH = 40

interface Props {
  menuItems: INewSubNavigation[]
  currentCategoryName: string
  setTemplateAndVisibilityForPanel: (
    childNavigation: INewSubNavigation,
    currentName: string
  ) => void
  setHiddenMenu: (hiddenMenu: INewSubNavigation[]) => void
  closePanel: () => void
  scrollContainerToTop: () => void
}

const ResponsiveMenu: React.FC<Props> = ({
  menuItems,
  currentCategoryName,
  setTemplateAndVisibilityForPanel,
  setHiddenMenu,
  scrollContainerToTop
}) => {
  const responsive = useResponsive()
  const [visibleMenu, setVisibleMenu] = useState<INewSubNavigation[]>([
    ...menuItems
  ])
  const containerRef = useRef<HTMLDivElement>(null)
  const visibleMenuRef = useRef<HTMLDivElement>(null)
  const itemWidths = useRef<number[]>([])
  // const [isOffsetWidthCalculated, setIsOffsetWidthCalculated] = useState(false)
  const isOffsetWidthCalculatedRef = useRef(false)
  // use ref to store the flag due to closure issue
  const isWiderThan2Xl = useRef(false)
  const isLegacy = useRef(false)

  useEffect(() => {
    isWiderThan2Xl.current = responsive?.['2xl']
    isLegacy.current = responsive.legacy
  }, [responsive])

  const findSplitIndex = (containerWidth: number) => {
    const outerContainerWidth = containerWidth - MORE_BUTTON_WIDTH
    let splitCount = 0
    let totalElementWidth = 0

    const MENU_ITEM_MARGIN = isWiderThan2Xl.current
      ? MENU_ITEM_MARGIN_FULL_SCREEN
      : MENU_ITEM_MARGIN_SMALLER_SCREEN

    for (let i = 0; i < itemWidths.current.length; i++) {
      const elementWidth = itemWidths.current[i] + MENU_ITEM_MARGIN
      totalElementWidth += elementWidth
      if (totalElementWidth > outerContainerWidth) {
        break
      }
      splitCount = i
    }
    return splitCount === itemWidths.current.length - 1 ? -1 : splitCount
  }

  const updateMenuItems = () => {
    if (!isLegacy.current) return

    let tempVisible: INewSubNavigation[] = []
    let tempHidden: INewSubNavigation[] = []

    if (!isOffsetWidthCalculatedRef.current) {
      isOffsetWidthCalculatedRef.current = true
      const _itemWidths: number[] = [] // store the width of each item
      for (let i = 0; i < menuItems.length; i++) {
        const item = visibleMenuRef.current?.children[i] as HTMLElement
        _itemWidths.push(item?.getBoundingClientRect()?.width || 0)
      }
      itemWidths.current = _itemWidths
    }

    const outerContainerWidth =
      containerRef.current?.getBoundingClientRect()?.width || 0
    const splitIndex = findSplitIndex(outerContainerWidth)

    if (splitIndex === -1) {
      tempVisible = menuItems
      tempHidden = []
    } else {
      tempVisible = menuItems.slice(0, splitIndex + 1)
      tempHidden = menuItems.slice(splitIndex + 1)

      if (tempHidden.length > 0) {
        tempVisible.push({
          childItemName: 'More',
          childId: 'more'
        })
      }
    }

    setVisibleMenu(tempVisible)
    setHiddenMenu(tempHidden)
  }

  useEffect(() => {
    updateMenuItems()
    window.addEventListener('resize', updateMenuItems)
    return () => {
      window.removeEventListener('resize', updateMenuItems)
    }
  }, [menuItems])

  return (
    <>
      <div
        ref={containerRef}
        className={`outer-container flex w-full 2xl:max-w-[1144px] overflow-x-auto legacy:overflow-hidden h-[44px]`}
      >
        <div
          className={`flex legacy:flex-wrap pl-[2px] gap-4 2xl:gap-8 ${isOffsetWidthCalculatedRef.current ? ' pr-0 ' : 'legacy:pr-[54px] 2xl:pr-[72px]'}`}
          ref={visibleMenuRef}
        >
          {map(responsive?.legacy ? visibleMenu : menuItems, item => {
            return (
              <div
                key={item.childId}
                data-category-name={currentCategoryName}
                className={`flex cursor-pointer h-full py-[10px] items-center border-b-[2px] legacy:border-b-[3px] border-solid ${
                  currentCategoryName === item.childItemName.toLowerCase()
                    ? 'border-[#E7131A]'
                    : 'border-[transparent]'
                }`}
                onClick={() => {
                  if (!isLegacy.current) {
                    scrollContainerToTop()
                  }
                  setTemplateAndVisibilityForPanel(
                    item,
                    item.childItemName.toLowerCase()
                  )
                }}
              >
                <a
                  href={item.childItemLink || '#'}
                  onClick={e => e.preventDefault()}
                  className={classNames(
                    'font-semibold text-[14px] leading-6 whitespace-nowrap text-[#333333] legacy:text-[#161616] !no-underline',
                    currentCategoryName !== item.childItemName.toLowerCase()
                      ? 'legacy:hover:text-[#5C5C5C]'
                      : 'hover:text-[#161616]'
                  )}
                >
                  {item.childItemName === 'Keyboards & MIDI'
                    ? 'Keys & MIDI'
                    : item.childItemName === 'DJ Equipment'
                      ? 'DJ Gear'
                      : item.childItemName}
                </a>
              </div>
            )
          })}
        </div>
      </div>
      <style jsx>{`
        .outer-container {
          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          ::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.06);
            border-radius: 3px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
          }

          ::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.12);
            border-radius: 3px;
            -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
          }
        }

        .outer-container {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
          }
        }
      `}</style>
    </>
  )
}

export default ResponsiveMenu
