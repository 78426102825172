import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { useGlobalHeaderMenuData } from '@hooks/global/new-header'
import { get } from 'lodash'

const NewHeaderRightNav: React.FC = () => {
  const { data: headerMenu } = useGlobalHeaderMenuData()

  if (!isArrayAndNotEmpty(headerMenu)) {
    return null
  }

  const rightNavArrObj = headerMenu.filter(
    item => item.parentMediaName === 'Right Menus'
  )
  const subNavigation = get(rightNavArrObj?.[0], 'subNavigation')

  if (!subNavigation || !isArrayAndNotEmpty(subNavigation)) {
    return null
  }
  return (
    <div className="w-[fit-content] justify-end shrink-0 h-full element-hidden legacy:flex items-center pl-[2px] gap-4 2xl:gap-6 overflow-y-hidden overflow-x-auto">
      {subNavigation.map(item => {
        return (
          <div className="cursor-pointer py-3" key={item.childItemName}>
            <a
              className="flex items-center text-sm font-semibold leading-6 whitespace-nowrap text-[#161616] hover:text-[#5C5C5C] !no-underline"
              href={item?.childItemLink}
            >
              {item.childItemName}
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default withErrorBoundary(NewHeaderRightNav)
