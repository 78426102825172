import { algoliaMainIndexName } from '@constants/algolia-plp/configure'
import { HEADER_MENU_RECENTLY_VIEWED } from '@constants/global'
import { algoliaClient } from '@helper/utils/algoliaClient'
import { useQuery } from '@tanstack/react-query'
import { useLocalStorageState } from 'ahooks'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

export const useRecentlyViewedForAlgolia = () => {
  const [recentItems = []] = useLocalStorageState<string[] | undefined>(
    'recentItems'
  )
  const filtersObjID = useMemo(() => {
    return recentItems.map(objectID => `objectID:"${objectID}"`).join(' OR ')
  }, [recentItems.length > 0])

  const search_recentView_params = filtersObjID
    ? {
        indexName: algoliaMainIndexName,
        params: {
          filters: filtersObjID,
          highlightPostTag: '__/ais-highlight__',
          highlightPreTag: '__ais-highlight__'
        }
      }
    : { indexName: algoliaMainIndexName, params: {} }

  const { data } = useQuery<any>({
    queryKey: [HEADER_MENU_RECENTLY_VIEWED, search_recentView_params],
    queryFn: async () => {
      try {
        const result = await algoliaClient.search([search_recentView_params])
        return result
      } catch (error) {
        console.error('Error fetching recently viewed items:', error)
        return { results: [] }
      }
    },
    enabled: !isEmpty(recentItems)
  })

  return data?.results?.[0]?.hits
}
