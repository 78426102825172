import { GC_DRAWER_HEADER_MEGA_MENU } from '@constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import Image from '@gc/ui-components/image'
import { useChangeDrawerArr } from '@hooks/global/drawer'

const NewHeaderShopMenu: React.FC = () => {
  const { changeDrawer } = useChangeDrawerArr()
  return (
    <button
      className="shop-menu order-1 w-11 h-11"
      onClick={() => {
        changeDrawer(GC_DRAWER_HEADER_MEGA_MENU)
      }}
    >
      <Image
        src="/assets/images/new-header/icon-shop-menu.svg"
        alt="icon shop menu"
        width={44}
        height={44}
        priority={true}
      />
    </button>
  )
}

export default withErrorBoundary(NewHeaderShopMenu)
