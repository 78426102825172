import type { INewMenu } from '@gc/common-lib/api/header/index'
import {
  QUERY_KEY_NEW_HEADER_ICONS_AND_TEXT,
  QUERY_KEY_NEW_HEADER_MENU
} from '@gc/common-lib/constants/global/queryKey'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

export interface IMonetateGlobalHeaderMenu {
  action: string
  actionDest?: string
  actionId: number
  selector?: string
  source: INewMenu[]
}

export const useGlobalHeaderMenuData = () => {
  const {
    data: queryData,
    isLoading,
    error
  } = useQuery<IMonetateGlobalHeaderMenu>({
    queryKey: [QUERY_KEY_NEW_HEADER_MENU],
    queryFn: () =>
      Promise.resolve({
        action: '',
        actionId: 0,
        source: []
      }),
    placeholderData: keepPreviousData
  })

  return {
    data: isEmpty(queryData) ? [] : (queryData?.source ?? []),
    isLoading,
    error
  }
}
export interface IGlobalHeaderIconsAndText {
  GCLogo: {
    imgSrc: string
    altText: string
  }
  proGearAdvisers: {
    imgSrc: string
    altText: string
    headerLine: string
    phoneImgSrc: string
    phoneAltText: string
    chatText: string
    phoneNumber?: string
  }
  search: {
    imgSrc: string
    altText: string
    placeHolder: string
  }
  store: {
    emptyImgSrc: string
    emptyAltText: string
    EmptyLabel: string
    filledImgSrc: string
    filledAltText: string
  }
  account: {
    loggedOutImgSrc: string
    loggedOutAltText: string
    loggedOutLabel: string
    loggedInImgSrc: string
    loggedInAltText: string
  }
  miniCart: {
    emptyImgSrc: string
    emptyAltText: string
    filledImgSrc: string
    filledAltText: string
  }
}
export interface IMonetateGlobalHeaderIconsAndText {
  action: string
  actionDest?: string
  actionId: number
  selector?: string
  source: IGlobalHeaderIconsAndText
}

export const useGlobalHeaderIconsAndTextData = () => {
  const {
    data: queryData,
    isLoading,
    error
  } = useQuery<IMonetateGlobalHeaderIconsAndText>({
    queryKey: [QUERY_KEY_NEW_HEADER_ICONS_AND_TEXT],
    queryFn: () =>
      Promise.resolve({
        action: '',
        actionId: 0,
        source: {} as IGlobalHeaderIconsAndText
      }),
    placeholderData: keepPreviousData
  })

  return {
    data: (isEmpty(queryData)
      ? {}
      : (queryData?.source ?? {})) as IGlobalHeaderIconsAndText,
    isLoading,
    error
  }
}
