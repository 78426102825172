import PromoMessage from '@bc/algolia-plp/header/promo'
import { ThirdPartJSInit } from '@bc/global/ThirdPartJSInit'
// import { PromoMessageTextComponent } from '@bc/pdp/PromoMessageTextComponent'
// import { PROMO_MESSAGE_MODAL } from '@constants/pdp'
import { GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT } from '@gc/common-lib/constants/global'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import Image from '@gc/ui-components/image'
import { useGlobalHeaderIconsAndTextData } from '@hooks/global/new-header'
import { useResponsiveBreakpoint } from '@hooks/global/useResponsiveBreakpoint'
// import { useQuery } from '@tanstack/react-query'
// import { TPromoMessageModal } from '@typings/pdp'
// import { isEmpty } from 'lodash'
import dynamic from 'next/dynamic'
import { useState } from 'react'

import NewHeaderAccount from './NewHeaderAccount'
import NewHeaderCategories from './NewHeaderCategories'
import NewHeaderGearAdvisors from './NewHeaderGearAdvisors'
import NewHeaderMiniCart from './NewHeaderMiniCart'
import NewHeaderSearch from './NewHeaderSearch'
import NewHeaderShopMenu from './NewHeaderShopMenu'

const NewHeaderStore = dynamic(() => import('./NewHeaderStore'), { ssr: false })
const ShopMenuModal = dynamic(() => import('./modal/shop-menu-modal'), {
  ssr: false
})

const NewHeaderLayout: React.FC = () => {
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const { isBiggerThanLegacy } = useResponsiveBreakpoint()
  const { callCenterNumber = GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT } =
    useHeaderUserInfo()
  const { data: HeaderIconsAndText } = useGlobalHeaderIconsAndTextData()
  const {
    imgSrc = '/assets/images/new-header/mobile-header-logo.svg',
    altText = 'Guitar Center logo'
  } = HeaderIconsAndText?.GCLogo ?? {}
  const {
    phoneImgSrc = '/assets/images/new-header/icon-phone.svg',
    phoneAltText = 'call center number'
  } = HeaderIconsAndText?.proGearAdvisers ?? {}

  // const { data: promoMessageData } = useQuery<TPromoMessageModal>({
  //   queryKey: [PROMO_MESSAGE_MODAL]
  // })

  return (
    <>
      <ThirdPartJSInit />
      <div className="header-layout print:hidden">
        <PromoMessage />

        <div className="relative z-[103] new-header-top bg-white">
          <div className="max-w-[1540px] w-full px-2 pt-3 legacy:!p-4 legacy:!px-5 mx-auto flex flex-wrap legacy:!flex-nowrap justify-between legacy:justify-around items-center gap-3 legacy:gap-8">
            <div className="flex legacy:hidden gap-1">
              <NewHeaderShopMenu />
              <button className="flex w-11 h-11 items-center justify-center order-2">
                <a
                  href={`tel:${callCenterNumber}`}
                  className="inline-block w-6 h-6"
                >
                  <Image
                    src={phoneImgSrc}
                    alt={phoneAltText}
                    width={24}
                    height={24}
                    priority={true}
                  />
                </a>
              </button>
            </div>
            <div className="flex shrink-0 order-2 legacy:order-1 w-[87px] h-11 legacy:w-[103px] legacy:h-[52px]">
              <a
                className="w-[87px] h-11 legacy:w-[103px] legacy:h-[52px]"
                href="/"
              >
                <Image
                  src={imgSrc}
                  alt={altText}
                  width={isBiggerThanLegacy ? 103 : 87}
                  height={isBiggerThanLegacy ? 52 : 44}
                  priority={true}
                />
              </a>
            </div>
            <NewHeaderGearAdvisors />
            <NewHeaderStore />
            <div className="flex items-center order-4 legacy:order-6">
              <NewHeaderAccount />
              <NewHeaderMiniCart />
            </div>
            <div className="search w-full order-5  legacy:order-2">
              <NewHeaderSearch
                isOpenSearch={showSearch}
                showSearch={setShowSearch}
              />
            </div>
          </div>
        </div>

        <NewHeaderCategories />

        <nav id="gc_mainNav" />
        <ShopMenuModal />
        {/* {!isEmpty(promoMessageData) && (
          <PromoMessageTextComponent data={promoMessageData} />
        )} */}

        <style jsx global>{`
          .gc-top-headroll-container a {
            font-weight: 700;
          }
          .header-layout {
            @media (max-width: 975.9px) {
              .pt-3 {
                padding-top: 0.75rem !important;
              }
            }
          }
          body.overlay-and-overflow-hidden {
            position: relative;
            &:before {
              display: block;
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              top: -16px;
              bottom: 0;
              background-color: #000;
              z-index: 51;
              opacity: 0.6;
            }
            .QSIFeedbackButton {
              z-index: 50 !important;
            }
            @media (max-width: 975px) {
              overflow: hidden;
              height: 100%;
              &:before {
                background-color: #fff;
                opacity: 1;
              }
            }
          }

          .ant-message-error .cs-tel,
          .ant-message-error .couponChat {
            font-weight: 500;
          }
          .ant-spin-nested-loading > div > .ant-spin {
            max-height: 100%;
          }
          .new-header-top a,
          .new-header-bottom a {
            font-family:
              var(--font-montserrat), Arial, Helvetica, sans-serif !important;
          }
        `}</style>
      </div>
    </>
  )
}

export default NewHeaderLayout
