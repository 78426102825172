import { GC_DRAWER_HEADER_SIGN_IN } from '@constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import { useUserLoginStatus } from '@gc/common-lib/hooks/userLogin'
import { eventEmitter, SIGN_IN_EVENT } from '@gc/common-lib/utils/eventEmitter'
import Image from '@gc/ui-components/image'
import { useSignModal } from '@hooks/global'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import { useGlobalHeaderIconsAndTextData } from '@hooks/global/new-header'
import { useResponsiveBreakpoint } from '@hooks/global/useResponsiveBreakpoint'
import { defaultActionParams, save } from '@store/global'
import type { RootState } from '@store/index'
import { useStoreInstance } from '@store/index'
import { Steps } from '@typings/global'
import { useLatest } from 'ahooks'
import { assign, capitalize } from 'lodash'
import dynamic from 'next/dynamic'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const WishlistConfirmModal = dynamic(
  () => import('@bc/global/sign-in-modal/WishlistConfirmModal'),
  {
    ssr: false
  }
)

const SignInModal = dynamic(() => import('@bc/global/sign-in-modal'), {
  ssr: false
})

const NewHeaderAccount: React.FC = () => {
  const { changeDrawer, judgeIsDisplayDrawer } = useChangeDrawerArr()
  const { isUserLogin, userFirstName, isLoading } = useUserLoginStatus()
  const {
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    changeStep,
    resetStep,
    actionParams,
    setActionParams
  } = useSignModal()

  const isLoginRef = useLatest(isUserLogin)
  const store = useStoreInstance()
  const { isBiggerThanLegacy } = useResponsiveBreakpoint()
  const [showWishlistConfirmModal, setShowWishlistConfirmModal] =
    useState(false)
  const selectedWishlist = useSelector(
    (state: RootState) => state.global.selectedWishlist
  )
  const { data: HeaderIconsAndText } = useGlobalHeaderIconsAndTextData()
  const {
    loggedOutImgSrc = '/assets/images/new-header/icon-account-loggedOut.svg',
    loggedOutAltText = 'logged out account icon',
    loggedOutLabel = 'Sign In',
    loggedInImgSrc = '/assets/images/new-header/icon-account-logged.svg',
    loggedInAltText = 'logged account icon'
  } = HeaderIconsAndText?.account ?? {}

  const { displayText, displayTitleText } = useMemo(() => {
    if (isLoading) return { displayText: null, displayTitleText: null }

    if (isUserLogin || userFirstName) {
      const name =
        userFirstName.length > 6
          ? `${userFirstName.slice(0, 6)}...`
          : userFirstName
      return {
        displayText: `Hi, ${capitalize(name)}`,
        displayTitleText: `Hi, ${capitalize(userFirstName)}`
      }
    }

    return { displayText: loggedOutLabel, displayTitleText: loggedOutLabel }
  }, [isLoading, isUserLogin, userFirstName, loggedOutLabel])

  const data = useHeaderFooterData()
  const storeConfig = {
    ...data.storeConfig?.value,
    recaptchaEnable: data.recaptchaEnable
  }

  const drawerArray = useSelector(
    (state: RootState) => state.global.drawerArray
  )
  const firstDrawer = drawerArray[0]

  useEffect(() => {
    const listener = (params = defaultActionParams) => {
      if (
        (isLoginRef.current && params?.targetStep === Steps.SignIn) ||
        params?.isSessionTimeout
      ) {
        return
      }
      const activeStep = !isLoginRef.current ? Steps.SignIn : params?.targetStep
      setActionParams(assign({}, defaultActionParams, params))
      setSteps([activeStep ?? Steps.SignIn])
      setActiveStep(activeStep)

      changeDrawer(GC_DRAWER_HEADER_SIGN_IN)
    }
    eventEmitter.on(SIGN_IN_EVENT, listener)
    return () => {
      eventEmitter.off(SIGN_IN_EVENT, listener)
    }
  }, [])

  return (
    <>
      <button
        className="shrink-0 flex justify-center legacy:justify-start w-11 max-w-[106px] legacy:w-[fit-content] h-11 legacy:h-[52px] legacy:pr-2 text-xs text-[#676767] leading-3 items-center cursor-pointer hover:bg-[#F5F5F5] rounded"
        onClick={() => {
          changeDrawer(GC_DRAWER_HEADER_SIGN_IN)
        }}
      >
        <span className="ShopLoginIcon flex items-center justify-center w-[30px] h-[30px]">
          {isUserLogin || userFirstName ? (
            <Image
              alt={loggedInAltText}
              src={loggedInImgSrc}
              className="mx-auto"
              width={16}
              height={21}
              priority={true}
            />
          ) : (
            <Image
              alt={loggedOutAltText}
              src={loggedOutImgSrc}
              className="mx-auto"
              width={16}
              height={21}
              priority={true}
            />
          )}
        </span>
        <span
          title={displayTitleText || loggedOutLabel}
          className="ShopLoginText element-hidden legacy:inline-block min-w-11 w-[fit-content] text-[#5c5c5c] text-xs leading-4 font-semibold whitespace-nowrap"
        >
          {displayText || loggedOutLabel}
        </span>
      </button>
      {judgeIsDisplayDrawer(GC_DRAWER_HEADER_SIGN_IN) ? (
        <SignInModal
          isLogin={isUserLogin}
          userFirstName={userFirstName}
          messageContent={data.messageContent?.contentItem ?? {}}
          storeConfig={storeConfig}
          steps={steps}
          setSteps={setSteps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          changeStep={changeStep}
          resetStep={resetStep}
          actionParams={actionParams}
          setActionParams={setActionParams}
          isLeftPlacement={
            !isBiggerThanLegacy && firstDrawer === 'megaMenu' ? true : false
          }
          setShowWishlistConfirmModal={setShowWishlistConfirmModal}
        />
      ) : null}

      {showWishlistConfirmModal ? (
        <WishlistConfirmModal
          product={actionParams.options?.product}
          onCancel={() => {
            setShowWishlistConfirmModal(false)
            setSteps([Steps.SignIn])
          }}
          onSuccess={() => {
            store.dispatch(save({ selectedWishlist: undefined }))
            setShowWishlistConfirmModal(false)
            actionParams?.options?.successCallback?.('added')
            setActionParams(defaultActionParams)
            setSteps([Steps.SignIn])
          }}
          selectedWishlist={selectedWishlist}
        />
      ) : null}

      <style jsx global>{`
        @media (max-width: 975.9px) {
          .ShopLogin {
            width: 64px !important;
            .ShopLoginText {
              display: none;
            }
            .ShopLoginIcon {
              top: 14.4px;
            }
          }
        }
      `}</style>
    </>
  )
}

export default withErrorBoundary(NewHeaderAccount)
