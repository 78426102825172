import { GC_DRAWER_HEADER_LIVE_HELP } from '@constants/global'
import { GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT } from '@gc/common-lib/constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { triggerChatButton } from '@gc/common-lib/utils/liveChat'
import Image from '@gc/ui-components/image'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import { useGlobalHeaderIconsAndTextData } from '@hooks/global/new-header'
import dynamic from 'next/dynamic'

const LiveHelpModal = dynamic(
  () => import('@bc/algolia-plp/header/modal/LiveHelpModal'),
  {
    ssr: false
  }
)

const NewHeaderGearAdvisors: React.FC = () => {
  const { changeDrawer, judgeIsDisplayDrawer } = useChangeDrawerArr()
  const { data: HeaderIconsAndText } = useGlobalHeaderIconsAndTextData()
  const {
    imgSrc = '',
    altText = 'gear advisor icon',
    headerLine = 'Pro Gear Advisers',
    chatText = 'Chat',
    phoneNumber = GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT
  } = HeaderIconsAndText?.proGearAdvisers ?? {}
  const { callCenterNumber = phoneNumber } = useHeaderUserInfo()

  return (
    <>
      <button
        className="shrink-0 element-hidden legacy:flex order-2 pr-2 items-center cursor-pointer hover:bg-[#F5F5F5] rounded"
        onClick={() => changeDrawer('liveHelp')}
      >
        {imgSrc !== '' && (
          <div className="flex items-center justify-center w-[52px] h-[52px]">
            <span className="w-9 h-9">
              <Image
                src={imgSrc}
                alt={altText}
                className="rounded-[10px]"
                width={36}
                height={36}
                priority={true}
              />
            </span>
          </div>
        )}
        <div className="flex flex-col gap-[6px] items-start">
          <div className="live-help-text text-sm leading-[14px] text-[#161616] font-semibold">
            {headerLine}
          </div>
          <div className="live-help-phone flex items-center gap-1 text-xs leading-3 text-[#5C5C5C] font-semibold">
            <span
              className="cursor-pointer underline"
              onClick={() => {
                changeDrawer('liveHelp')
                triggerChatButton()
              }}
            >
              {chatText}
            </span>
            <span className="relative top-[-4px] text-base leading-3">.</span>
            <span>{callCenterNumber}</span>
          </div>
        </div>
      </button>
      {judgeIsDisplayDrawer(GC_DRAWER_HEADER_LIVE_HELP) ? (
        <LiveHelpModal isLeftPlacement={true} />
      ) : null}
    </>
  )
}

export default withErrorBoundary(NewHeaderGearAdvisors)
