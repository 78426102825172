import { IMAGE_PREFIX } from '@constants/media'
import type { IAlgoliaRecentlyItems } from '@gc/common-lib/api/header'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Rate from '@gc/ui-components/rate'
import { useFormatCurrency } from '@hooks/global'
import classNames from 'classnames'
import { map } from 'lodash'

export const RecentlyItem: React.FC<IAlgoliaRecentlyItems> = item => {
  const {
    review: { totalReviews, overallRating },
    seoUrl,
    price,
    imageId,
    listPrice,
    displayName
  } = item
  const savings = listPrice ? listPrice - price : 0
  const { userLocale: { currencySymbol = '$' } = {} } = useHeaderUserInfo()

  const formatCurrency = useFormatCurrency(currencySymbol)

  return (
    <div className="flex flex-row gap-2">
      <a
        href={`${seoUrl}${
          seoUrl.includes('?') ? '&' : '?'
        }pdptypeAheadSearchInput=&tNtt=&tAt=item&tAv=${displayName}`}
        data-name={displayName}
        className="flex w-[136px] h-[136px] box-border p-2 items-center justify-center bg-[#F5F5F5]"
      >
        <span className="flex items-center justify-center w-[116px] h-[116px]">
          <Image
            className="mix-blend-darken"
            src={specifyImageSize(
              `${IMAGE_PREFIX}MMGS7/${imageId}-00-120x120.jpg`,
              116
            )}
            alt="search production"
            style={{ width: '100%', height: '100%' }}
            width={116}
            height={116}
          />
        </span>
      </a>
      <div className="flex flex-col flex-1 max-w-[500px] legacy:w-[203px]">
        <div className="product-title py-1">
          <a
            href={`${seoUrl}${
              seoUrl.includes('?') ? '&' : '?'
            }pdptypeAheadSearchInput=&tNtt=&tAt=item&tAv=${displayName}`}
            className="three-line-text text-sm leading-5 font-semibold !text-[#161616] hover:underline"
          >
            {displayName}
          </a>
        </div>
        <a
          href={`${seoUrl}${
            seoUrl.includes('?') ? '&' : '?'
          }pdptypeAheadSearchInput=&tNtt=&tAt=item&tAv=${displayName}`}
          className="flex flex-row flex-nowrap items-center cursor-pointer h-[22px] pb-1 leading-[18px]"
        >
          <Rate rating={overallRating || 0} className="leading-[18px]" />

          <span className="text-xs text-[#161616] font-normal leading-3 tracking-tight">
            ({totalReviews || 0})
          </span>
        </a>
        <div className="flex flex-row flex-nowrap h-6 items-center gap-[6px] m-0 leading-[20px]">
          {/* price could be string, need to convert to number */}
          {price ? (
            <span
              className={classNames(
                'text-base font-bold',
                listPrice !== price ? 'text-[#E7131A]' : 'text-[#161616]'
              )}
            >
              {formatCurrency(price)}
            </span>
          ) : (
            <span className="text-xs font-bold">Contact for Price</span>
          )}
          {listPrice && listPrice !== price && (
            <div className="text-xs text-[#676767] leading-5 font-normal line-through">
              {formatCurrency(listPrice)}
            </div>
          )}
        </div>
        {listPrice !== price ? (
          <div className="inline-block w-[fit-content] px-2 py-[6px] bg-[#FF553329] text-xs font-semibold text-[#161616] leading-3">
            Save {formatCurrency(savings)}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const RecentlyViewedCarousel: React.FC<{
  recentlyItems: IAlgoliaRecentlyItems[]
}> = props => {
  const { recentlyItems } = props
  return (
    <div className={classNames('flex flex-wrap gap-4')}>
      {map(recentlyItems, item => (
        <RecentlyItem key={item.skuId} {...item} />
      ))}
    </div>
  )
}

export default withErrorBoundary(RecentlyViewedCarousel)
