import type {
  INavigationItem,
  INewSubNavigation,
  ISubCategory
} from '@gc/common-lib/api/header'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import Image from '@gc/ui-components/image'
import { useRecentlyViewedForAlgolia } from '@hooks/global/algolia-recentlyViewed'
import { useResponsiveBreakpoint } from '@hooks/global/useResponsiveBreakpoint'
import classNames from 'classnames'
import { cloneDeep, filter, includes, isEmpty, map } from 'lodash'
import { useEffect, useState } from 'react'

import RightArrowIcon from '../icons/RightArrowIcon'
import RecentlyViewedCarousel from '../RecentlyViewedCarousel'
import NewHeaderDailyPick from './NewHeaderDailyPick'

const getWrappedCateArrays = (
  subCategories: ISubCategory[],
  maxColumnNumber: number
): { wrappedCateArr?: ISubCategory[]; wrappedCateArrName?: string[] } => {
  const regular =
    filter(
      subCategories,
      item => item.itemName?.toLowerCase() !== 'popular brands'
    ) || []
  if (regular.length <= maxColumnNumber) {
    return { wrappedCateArr: [], wrappedCateArrName: [] }
  }
  const descendingRegular = cloneDeep(regular).sort(
    (b1, b2) => b2.itemNavigation.length - b1.itemNavigation.length
  )
  const pickShorterArr = descendingRegular.slice(maxColumnNumber)
  const pickShorterArrName = pickShorterArr
    .map(item => item.itemName)
    .filter((name): name is string => name !== undefined)

  return {
    wrappedCateArr: pickShorterArr,
    wrappedCateArrName: pickShorterArrName
  }
}

const normalGrayLinkClassName = {
  liClassName: 'flex items-center py-[3px] legacy:!py-0',
  aClassName:
    'font-medium py-3 legacy:!py-0 text-base legacy:text-sm leading-4 legacy:leading-5 tracking-[1%] text-[#676767] hover:underline'
}
const BoldBlackLinkClassName = {
  liClassName:
    'flex items-center pl-4 legacy:!pl-0 py-3 legacy:!pb-2 legacy:!pt-0 h-[72px] legacy:h-auto border-b-[1px] border-solid border-[#DDDDDD] legacy:border-none',
  aClassName:
    'font-semibold text-sm leading-4 legacy:leading-5 text-black !no-underline legacy:hover:text-[#676767]'
}

interface IInnerLinkProps extends INavigationItem {
  eleClassName: { liClassName: string; aClassName: string }
}

const InnerLink: React.FC<IInnerLinkProps> = ({ name, link, eleClassName }) => {
  return (
    <li className={eleClassName.liClassName}>
      <a className={eleClassName.aClassName} href={link}>
        {name}
      </a>
    </li>
  )
}

const SpecialCategories: React.FC<{ specialCategories: INavigationItem[] }> = ({
  specialCategories
}) => {
  return (
    <ul className="special ml-0 pl-0 pt-0 flex flex-col legacy:gap-4 w-full legacy:min-w-[174px] lg:min-w-0">
      {map(specialCategories, item => (
        <InnerLink
          {...item}
          key={item.name}
          eleClassName={BoldBlackLinkClassName}
        />
      ))}
    </ul>
  )
}

const PromoAdImg: React.FC<{ name?: string; imgUrl?: string }> = promoAds => {
  return (
    <Image
      alt={promoAds?.name || 'Image is not available at this moment'}
      src={promoAds?.imgUrl || ''}
      width={282}
      height={164}
      style={{
        width: '100%',
        maxWidth: '282px',
        height: 'auto',
        maxHeight: '164px',
        objectFit: 'contain'
      }}
      sizes="(max-width: 282px) 100vw, 282px"
    />
  )
}

const SubCategoryModalContent: React.FC<{
  menus: INewSubNavigation[]
  activeCategoryName: string
  showHiddenMenu: boolean
  hiddenMenu: INewSubNavigation[]
  featuredCategories: INewSubNavigation[]
  closePanel: () => void
  scrollContainerToTop: () => void
}> = ({
  menus,
  activeCategoryName,
  showHiddenMenu,
  hiddenMenu,
  featuredCategories,
  closePanel,
  scrollContainerToTop
}) => {
  const {
    isBiggerThanLegacy,
    isBiggerThanLarge: isBiggerThanTablet,
    isBiggerThanXl: isBiggerThanExtraLarge
  } = useResponsiveBreakpoint()
  const moreMenu = isArrayAndNotEmpty(featuredCategories)
    ? [...hiddenMenu, ...featuredCategories]
    : hiddenMenu
  const [currentCategoryName, setCurrentCategoryName] = useState('')
  const [maxColumnNumber, setMaxColumnNumber] = useState(4)

  const [thirdMenuData, setThirdMenuData] = useState<ISubCategory | null>(null)

  const [showThirdMenu, setShowThirdMenu] = useState<boolean>(false)

  const [isScrolled, setIsScrolled] = useState(false)

  const searchResult = useRecentlyViewedForAlgolia()

  useEffect(() => {
    if (showHiddenMenu) {
      setCurrentCategoryName(hiddenMenu?.[0].childItemName.toLowerCase())
      if (!isBiggerThanLegacy) closePanel()
    }
    setMaxColumnNumber(isBiggerThanTablet || !showHiddenMenu ? 4 : 3)
  }, [showHiddenMenu, isBiggerThanTablet])

  useEffect(() => {
    setCurrentCategoryName(
      showHiddenMenu
        ? hiddenMenu?.[0]?.childItemName?.toLowerCase()
        : activeCategoryName
    )
    setShowThirdMenu(false)
  }, [activeCategoryName, showHiddenMenu, hiddenMenu])

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 194)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const showSubCategoryDetail = (
    shouldShowSubCategoryFLag: boolean,
    subCategoryData: ISubCategory | null
  ) => {
    scrollContainerToTop()
    if (shouldShowSubCategoryFLag) {
      setThirdMenuData(subCategoryData)
      setShowThirdMenu(true)
    } else {
      setThirdMenuData(null)
      setShowThirdMenu(false)
    }
  }

  return (
    <>
      <div className="flex w-full">
        {/* {left category menus} */}
        <div
          className={classNames(
            showHiddenMenu && isBiggerThanLegacy ? 'block' : 'element-hidden',
            'left-menu p-6 w-[181px] h-full overflow-y-auto border-solid border-r border-[#DDDDDD] bg-[#F9F9F9]'
          )}
        >
          {map(moreMenu, item => (
            <div key={item.childId} data-key={item.childId}>
              {item?.childNavigation ? (
                <a
                  onClick={e => {
                    e.preventDefault()
                    setCurrentCategoryName(item.childItemName.toLowerCase())
                  }}
                  className={`mb-6 pb-6 cursor-pointer block font-sans font-semibold text-[14px] text-black !no-underline leading-6 border-b-[3px] h-[22px] border-solid whitespace-nowrap ${
                    currentCategoryName === item.childItemName.toLowerCase()
                      ? 'border-[#E7131A] hover:text-black'
                      : 'border-[transparent] legacy:hover:text-[#5C5C5C]'
                  }`}
                  href={item.childItemLink}
                >
                  {item.childItemName}
                </a>
              ) : (
                <a
                  className="flex justify-between w-full mb-6 pb-6 cursor-pointer font-sans font-semibold text-[14px] text-black !no-underline legacy:hover:text-[#5C5C5C] leading-6 h-[22px] whitespace-nowrap"
                  href={item.childItemLink}
                >
                  {item.childItemName}
                  <RightArrowIcon className="w-[18px] -rotate-90" />
                </a>
              )}
            </div>
          ))}
          {isArrayAndNotEmpty(searchResult) && (
            <button
              onClick={() => {
                setCurrentCategoryName('Recently Viewed'.toLowerCase())
              }}
              className={`mb-6 pb-6 cursor-pointer inline-block w-[fit-content] font-sans font-semibold text-[14px] text-black !no-underline leading-6 border-b-[3px] h-[22px] border-solid whitespace-nowrap ${
                currentCategoryName === 'Recently Viewed'.toLowerCase()
                  ? 'border-[#E7131A] hover:text-black'
                  : 'border-[transparent] legacy:hover:text-[#5C5C5C]'
              }`}
            >
              Recently Viewed
            </button>
          )}
        </div>

        {/* {category(desktop+mobile) and desktop sub-category} */}
        {map(
          isArrayAndNotEmpty(featuredCategories)
            ? [...menus, ...featuredCategories]
            : menus,
          items => {
            const {
              childItemName,
              childItemLink,
              childNavigation: {
                subCategories = [],
                specialCategories,
                promoCategories,
                promoAds
              } = {}
            } = items

            const { wrappedCateArr, wrappedCateArrName } = getWrappedCateArrays(
              subCategories,
              maxColumnNumber - 1
            )

            const getSubCategoriesItemWith = () => {
              if (subCategories.length <= maxColumnNumber) {
                return 'flex-1'
              } else {
                // if the subCategories length is more than maxColumnNumber
                if (isBiggerThanExtraLarge) {
                  // `max-w-[calc((100%-282px)/${maxColumnNumber}-${isBiggerThanExtraLarge ? '24px' : '16px'})]`
                  return `legacy:max-w-[calc(25%-94.5px)]`
                } else {
                  // if not have left menu (mean this is not more menu)
                  if (!showHiddenMenu) {
                    if (subCategories.length === 3) {
                      return 'legacy:max-w-[calc(25%-12px)]'
                    } else if (subCategories.length > 3) {
                      return 'legacy:max-w-[calc(20%-12.8px)]'
                    } else {
                      return `legacy:max-w-[calc(25%-94.5px)]`
                    }
                  } else {
                    if (subCategories.length === 2) {
                      return 'legacy:max-w-[calc(33.33%-10.67px)]'
                    } else if (subCategories.length > 2) {
                      return 'legacy:max-w-[calc(25%-12px)]'
                    } else {
                      return `legacy:max-w-[calc(25%-94.5px)]`
                    }
                  }
                }
              }
            }

            const getRightAreaWidthClass = () => {
              if (isBiggerThanExtraLarge) {
                return 'legacy:max-w-[282px]'
              } else {
                // if not have left menu (mean this is not more menu)
                if (!showHiddenMenu) {
                  if (subCategories.length === 3) {
                    return 'legacy:max-w-[calc(25%-12px)]'
                  } else if (subCategories.length > 3) {
                    return 'legacy:max-w-[calc(20%-12.8px)]'
                  } else {
                    return 'legacy:max-w-[282px]'
                  }
                } else {
                  if (subCategories.length === 2) {
                    return 'legacy:max-w-[calc(33.33%-10.67px)]'
                  } else if (subCategories.length > 2) {
                    return 'legacy:max-w-[calc(25%-12px)]'
                  } else {
                    return 'legacy:max-w-[282px]'
                  }
                }
              }
            }

            return (
              <div
                key={childItemName}
                data-name={childItemName}
                className={classNames(
                  'flex-col legacy:px-6 legacy:pt-6 legacy:pb-8',
                  showHiddenMenu && isBiggerThanLegacy
                    ? 'w-[calc(100%-176px)]'
                    : 'w-full',
                  items?.childItemName.toLowerCase() === currentCategoryName
                    ? 'flex'
                    : 'element-hidden'
                )}
              >
                {items?.childNavigation && (
                  <div className="sub-category-content">
                    <div className="element-hidden legacy:flex flex-wrap flex-col legacy:flex-row gap-6 items-center min-h-9 pl-0 py-0 mb-8 text-[26px] font-bold leading-8 text-black">
                      <span className="inline-block self-start">
                        {childItemName}
                      </span>
                      {childItemLink && (
                        <div className="flex flex-1 w-full justify-between">
                          <a
                            className="flex items-center bg-[#EEEEEE] hover:bg-[#DDDDDD] min-w-[48px] h-9 py-[12.5px] px-3 rounded text-base !text-[#161616] !no-underline leading-4 font-semibold"
                            href={childItemLink}
                          >
                            Shop All {childItemName}
                          </a>
                          <button
                            className="inline-block h-9 w-9 p-[6px] cursor-pointer rounded-full bg-[#EEEEEE] hover:bg-[#DDDDDD]"
                            onClick={closePanel}
                          >
                            <Image
                              alt="close icon"
                              src="/assets/images/new-header/icon-clear.svg"
                              width={24}
                              height={24}
                              className="mx-auto"
                              priority={true}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    <div
                      className={`sticky top-0 flex w-full legacy:hidden justify-between px-4 py-4 bg-white z-[101] ${isScrolled ? 'border-b border-[#ddd]' : ''}`}
                    >
                      {showThirdMenu ? (
                        <button
                          className="flex relative items-center bg-[#EEEEEE] min-w-[48px] w-[fit-content] h-9 py-[12.5px] px-3 rounded text-base text-black !no-underline leading-4 font-semibold cursor-pointer"
                          onClick={() => {
                            showSubCategoryDetail(false, null)
                          }}
                        >
                          <span className="go-back ml-4">Back</span>
                        </button>
                      ) : (
                        <a
                          className="flex items-center bg-[#EEEEEE] min-w-[48px] h-9 py-[12.5px] px-3 rounded text-base !text-[#161616] !no-underline leading-4 font-semibold"
                          href={childItemLink}
                        >
                          Shop All {childItemName}
                        </a>
                      )}

                      <button
                        className="inline-block h-9 w-9 p-[6px] cursor-pointer rounded-full bg-[#EEEEEE] hover:bg-[#DDDDDD]"
                        onClick={closePanel}
                      >
                        <Image
                          alt="close icon"
                          src="/assets/images/new-header/icon-clear.svg"
                          width={24}
                          height={24}
                          className="mx-auto"
                          priority={true}
                        />
                      </button>
                    </div>

                    <div>
                      <div className="block legacy:hidden min-h-9 pb-6 px-4 text-[24px] font-bold leading-8 text-black bg-[#FFFFFF]">
                        <span className="block self-start">
                          {showThirdMenu
                            ? thirdMenuData?.itemName
                            : childItemName}
                        </span>
                      </div>

                      {/* {all links container / replace container (use showCategoryDetail to judge)} */}
                      <div className="links-area legacy:flex gap-4 xl:gap-6 w-full bg-[#FFFFFF] legacy:!bg-inherit">
                        {isArrayAndNotEmpty(subCategories) &&
                          map(subCategories, (item, index) => (
                            <div
                              key={'subCategories' + index}
                              className={classNames(
                                'subCategories w-full',
                                includes(wrappedCateArrName, item.itemName) &&
                                  'legacy:hidden',
                                !item?.itemName &&
                                  !showThirdMenu &&
                                  'border-t-[1px] border-solid border-[#DDDDDD] legacy:border-none',
                                getSubCategoriesItemWith()
                              )}
                            >
                              {/* {mobile, has item name cate show the special menu before popular (withSEO)} */}
                              {isArrayAndNotEmpty(specialCategories) && (
                                <div
                                  className={classNames(
                                    'special-prepend-pop-brand',
                                    !showThirdMenu &&
                                      item.itemName?.toLowerCase() ===
                                        'popular brands'
                                      ? 'block legacy:hidden'
                                      : 'element-hidden'
                                  )}
                                >
                                  <SpecialCategories
                                    specialCategories={specialCategories}
                                  />
                                </div>
                              )}

                              {/* {both desktop and mobile, map all second and third categories } */}
                              <ul
                                onClick={() =>
                                  !isBiggerThanLegacy &&
                                  showSubCategoryDetail(true, item)
                                }
                              >
                                <li
                                  className={classNames(
                                    index === 0 &&
                                      item.itemName &&
                                      !showThirdMenu &&
                                      'border-t-[1px]',
                                    !showThirdMenu &&
                                      'border-b-[1px] border-solid border-[#DDDDDD] legacy:border-none'
                                  )}
                                >
                                  {/* {both desktop and mobile, second menu, (withSEO)} */}
                                  <div
                                    className={classNames(
                                      item.itemName && !showThirdMenu
                                        ? 'flex right-arrow mobile'
                                        : 'element-hidden legacy:flex',
                                      'items-center pl-4 legacy:!pl-0 py-3 legacy:!pt-0 legacy:!pb-3 h-[72px] legacy:h-auto leading-5 legacy:mb-4 legacy:border-b-[1px] legacy:border-solid legacy:border-[#DDDDDD]'
                                    )}
                                  >
                                    {item.itemLink ? (
                                      <a
                                        className="text-sm leading-5 font-semibold text-black !no-underline legacy:hover:text-[#676767]"
                                        href={item.itemLink}
                                        onClick={e => {
                                          if (!isBiggerThanLegacy)
                                            e.preventDefault()
                                        }}
                                      >
                                        {item.itemName}
                                      </a>
                                    ) : (
                                      <span className="block text-sm leading-5 font-semibold text-black cursor-pointer legacy:cursor-default">
                                        {item.itemName || childItemName}
                                      </span>
                                    )}
                                  </div>

                                  {/* {both desktop and mobile, third menu map, (withSEO)} */}
                                  <ul
                                    className={classNames(
                                      'w-full font-medium text-base pl-4 pr-3 pb-[6px] legacy:!p-0 flex-col legacy:gap-4 leading-4 legacy:text-sm',
                                      item.itemName &&
                                        'border-t-[1px] border-solid border-[#DDDDDD] legacy:border-none',
                                      isBiggerThanLegacy ||
                                        (showThirdMenu &&
                                          thirdMenuData?.itemName ===
                                            item.itemName) ||
                                        (!showThirdMenu && !item.itemName)
                                        ? 'flex'
                                        : 'element-hidden'
                                    )}
                                  >
                                    {map(item.itemNavigation, NavItem => (
                                      <InnerLink
                                        {...NavItem}
                                        key={NavItem.name}
                                        eleClassName={normalGrayLinkClassName}
                                      />
                                    ))}
                                  </ul>

                                  {/* { mobile, has no item name cate display special menu (withSEO)} */}
                                  {isArrayAndNotEmpty(specialCategories) &&
                                    !item.itemName &&
                                    !showThirdMenu && (
                                      <div className="no-item-name-special-menu block legacy:hidden border-t-[1px] border-solid border-[#DDDDDD]">
                                        <SpecialCategories
                                          specialCategories={specialCategories}
                                        />
                                      </div>
                                    )}

                                  {index === 0 && isBiggerThanLegacy && (
                                    <>
                                      {/* {desktop insert the wrapped second and third menu to first column, (withoutSEO)} */}
                                      {isArrayAndNotEmpty(wrappedCateArr) &&
                                        map(
                                          wrappedCateArr,
                                          (CateItem, index) => (
                                            <ul
                                              key={'wrappedSubCate' + index}
                                              className="wrappedSubCategories w-full mt-6"
                                            >
                                              <li>
                                                <div className="flex items-center pb-3 h-auto leading-5 mb-4 border-b-[1px] border-solid border-[#DDDDDD]">
                                                  <a
                                                    className="text-sm leading-5 font-semibold text-black !no-underline hover:text-[#676767]"
                                                    href={CateItem.itemLink}
                                                  >
                                                    {CateItem.itemName}
                                                  </a>
                                                </div>

                                                <ul className="font-medium flex flex-col gap-4 leading-4 text-sm">
                                                  {map(
                                                    CateItem.itemNavigation,
                                                    NavItem => (
                                                      <InnerLink
                                                        {...NavItem}
                                                        key={NavItem.name}
                                                        eleClassName={
                                                          normalGrayLinkClassName
                                                        }
                                                      />
                                                    )
                                                  )}
                                                </ul>
                                              </li>
                                            </ul>
                                          )
                                        )}

                                      {/* {desktop append special categories to first column, (withoutSEO)}} */}
                                      {isArrayAndNotEmpty(
                                        specialCategories
                                      ) && (
                                        <div className="specialWrapper w-full ml-0 pl-0 pt-6">
                                          <SpecialCategories
                                            specialCategories={
                                              specialCategories
                                            }
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </li>
                              </ul>
                            </div>
                          ))}

                        {!isEmpty(promoAds) ||
                        isArrayAndNotEmpty(promoCategories) ? (
                          <div
                            className={classNames(
                              'right-area shrink-0 w-full mt-6 legacy:!mt-0',
                              getRightAreaWidthClass()
                            )}
                          >
                            {!isEmpty(promoAds) && (
                              <div className="element-hidden legacy:flex justify-center mix-blend-darken items-center w-full h-[104px] legacy:h-[164px] overflow-hidden">
                                {promoAds?.linkUrl ? (
                                  <a
                                    className="promo-ad-spot max-w-full max-h-full items-center justify-center mix-blend-darken"
                                    href={promoAds.linkUrl}
                                  >
                                    <PromoAdImg {...promoAds} />
                                  </a>
                                ) : (
                                  <PromoAdImg {...promoAds} />
                                )}
                              </div>
                            )}
                            {isArrayAndNotEmpty(promoCategories) && (
                              <>
                                {isBiggerThanLegacy &&
                                  childItemName === 'Deals' &&
                                  showHiddenMenu && <NewHeaderDailyPick />}
                                {/* {both desktop and mobile promo category (withSEO)} */}
                                <ul
                                  className={classNames(
                                    'flex-col legacy:gap-4 mt-6 legacy:!mt-8',
                                    showThirdMenu ? 'element-hidden' : 'flex'
                                  )}
                                >
                                  {map(promoCategories, item => (
                                    <InnerLink
                                      {...item}
                                      key={item.name}
                                      eleClassName={BoldBlackLinkClassName}
                                    />
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
                <div className="py-3 legacy:hidden">&nbsp;</div>
              </div>
            )
          }
        )}

        {/* {recently viewed items} */}
        {isArrayAndNotEmpty(searchResult) && (
          <div
            className={classNames(
              'recently-viewed-content flex-col legacy:px-6 legacy:pt-6 legacy:pb-8',
              showHiddenMenu && isBiggerThanLegacy
                ? 'w-[calc(100%-176px)]'
                : 'w-full',
              currentCategoryName === 'recently viewed'
                ? 'flex'
                : 'element-hidden'
            )}
          >
            <div className="flex flex-wrap justify-between gap-6 items-center min-h-9 pb-8 pl-4 mb-8 legacy:!pl-0 text-[24px] legacy:text-[26px] font-bold leading-8 text-black border-b-[1px] border-solid border-[#DDDDDD]">
              <span>Recently Viewed</span>

              <button
                className="inline-block h-9 w-9 p-[6px] cursor-pointer rounded-full bg-[#EEEEEE] hover:bg-[#DDDDDD]"
                onClick={closePanel}
              >
                <Image
                  alt="close icon"
                  src="/assets/images/new-header/icon-clear.svg"
                  width={24}
                  height={24}
                  className="mx-auto"
                  priority={true}
                />
              </button>
            </div>
            <RecentlyViewedCarousel recentlyItems={searchResult} />
          </div>
        )}
      </div>
      <style jsx global>{`
        .go-back.ml-4 {
          margin-left: 1rem !important;
        }
        .wrappedSubCategories {
          .pb-3 {
            padding-bottom: 0.75rem !important;
          }
          .mb-4 {
            margin-bottom: 1rem !important;
          }
        }
        .sub-category-content {
          .py-4 {
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
          }
          @media (max-width: 975.9px) {
            .pl-4 {
              padding-left: 1rem !important;
            }

            .py-3 {
              padding-top: 0.75rem !important;
              padding-bottom: 0.75rem !important;
            }
          }
          .px-3 {
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
          }
          .px-4 {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
          }
          ul {
            list-style: none;
          }
          & li > a:hover {
            text-decoration: underline;
          }
          @media (max-width: 975.9px) {
            .right-arrow {
              position: relative;
            }
            .right-arrow.mobile:before {
              position: absolute;
              top: 18px;
              right: 16px;
              content: '';
              display: block;
              height: 36px;
              width: 36px;
              padding: 8px;
              background-image: url(/assets/images/new-header/icon-down.svg);
              background-repeat: no-repeat;
              background-position: center;
              cursor: pointer;
              transform: rotate(-90deg);
              transition: transform 0.3s ease-in-out;
              transform-origin: center;
            }
          }
        }
        .no-item-name-special-menu .special li:last-child {
          border: none !important;
        }

        .go-back::before {
          position: absolute;
          top: 9px;
          left: 8px;
          content: '';
          height: 18px;
          width: 18px;
          padding: 4px;
          background-image: url(/assets/images/new-header/icon-down.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transform: rotate(90deg);
        }
      `}</style>
    </>
  )
}

export default SubCategoryModalContent
