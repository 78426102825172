import { QUERY_KEY_HOMEPAGE_DAILY_PICK } from '@constants/query/home'
import ATGService from '@gc/common-lib/api/request'
import { logger } from '@gc/common-lib/utils/logger'
import { useQuery } from '@tanstack/react-query'

const dailyPickQueryData = {
  url: '/ngp/rest/actor/SearchActor/emTemplateJSON',
  params: {
    includePath: '/pages/AppHeadlinerDeals'
  }
}

export const useDailyPickData = () => {
  const { data, isPending } = useQuery({
    queryKey: [
      QUERY_KEY_HOMEPAGE_DAILY_PICK,
      dailyPickQueryData.url,
      dailyPickQueryData.params
    ],
    queryFn: () => {
      return ATGService({
        url: dailyPickQueryData.url,
        params: dailyPickQueryData.params,
        method: 'get'
      })
        .then(result => {
          const results: any = result.data
          return results
        })
        .catch(error => {
          logger.error({
            error,
            message: 'Get Daily pick data request from ATG API failed'
          })
          return null
        })
    }
  })

  return { data, isPending }
}
