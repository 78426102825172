import { ONE_DAY } from '@gc/common-lib/constants/time'
import CountDown from '@gc/ui-components/countdown'
import Image from '@gc/ui-components/image'
import Rate from '@gc/ui-components/rate'
import { useDailyPickData } from '@hooks/global/daily-pick'
import { useMemo } from 'react'

const NewHeaderDailyPick: React.FC = () => {
  const { data, isPending } = useDailyPickData()

  const dailyPick = useMemo(() => {
    if (data?.emPageData?.Section && !isPending) {
      const Section = data.emPageData.Section

      const productsSection = Section.find(
        section => section.cartridge === 'MobileTargetter'
      )
      // Check if products data are missing
      if (!productsSection?.products?.length) {
        return null
      }
      const products = productsSection.products
      return {
        link: products[0].path,
        imageSrc: products[0].thumb,
        regularPrice: products[0].regularPrice,
        price: products[0].price,
        savings: products[0].savings,
        rating: products[0].rating,
        reviews: products[0].reviews,
        displayName: products[0].displayName,
        promoEndTimestamp:
          products[0]?.promoEndTimestamp || products[0]?.endTimestamp || 0
      }
    }
    return null
  }, [data, isPending])

  const todayMS = new Date().getTime()
  if (dailyPick) {
    const {
      link,
      imageSrc,
      regularPrice,
      price,
      savings,
      rating,
      reviews,
      displayName,
      promoEndTimestamp
    } = dailyPick
    if (promoEndTimestamp - todayMS < 0) {
      return null
    }
    const leftMilliseconds = () => {
      const leftDay = (promoEndTimestamp - todayMS) / ONE_DAY
      if (leftDay > 1) {
        const msInOneDay = promoEndTimestamp - Math.floor(leftDay) * ONE_DAY
        return msInOneDay
      }
      return promoEndTimestamp
    }
    return (
      <div className="new-header-daily-pick flex flex-col p-4 bg-[#EEEEEE]">
        <div className="flex flex-row justify-between mb-4 h-[22px]">
          <span className="text-sm text-black leading-[14px] font-semibold">
            Daily Pick
          </span>
          <span className="countDownDate text-sm leading-[14px] font-semibold text-[#1F871C]">
            <CountDown
              value={leftMilliseconds()}
              format="HH:mm:ss"
              valueStyle={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#1F871C',
                lineHeight: '14px'
              }}
            />
          </span>
        </div>

        <div className="flex h-[141px] flex-row justify-between">
          <a
            href={link}
            className="shrink-0 h-[121px] w-[121px] flex items-center justify-center mr-2 bg-[#F5F5F5]"
            title="Daily Pick"
          >
            <span className=" w-[105px] h-[105px] mix-blend-darken">
              <Image
                src={imageSrc}
                width={105}
                height={105}
                alt="Daily Pick Image"
                className="mix-blend-darken"
              />
            </span>
          </a>
          <div className="flex flex-col max-w-[121px]">
            <a
              href={link}
              className="dailyPickProdName two-line-text h-11 mb-1 text-sm !text-[#161616] font-semibold leading-[22px]"
              title={displayName}
            >
              {displayName}
            </a>
            <div className="flex flex-row flex-nowrap items-center">
              <Rate
                rating={rating || 0}
                className="text-base leading-none h-4"
                containerClassName="leading-[18px]"
              />{' '}
              <span className="text-xs text-[#161616] font-normal leading-4 tracking-tight">
                ({reviews})
              </span>
            </div>
            <div className="flex flex-row flex-wrap items-center gap-x-[6px]">
              <div className="text-sm leading-5 font-bold text-[#E7131A]">
                {price}
              </div>
              {savings !== '$.00' && (
                <div className="text-xs text-[#676767] leading-5 font-normal line-through">
                  {regularPrice}
                </div>
              )}
            </div>

            {savings !== '$.00' && (
              <div className="inline-block w-[fit-content] px-2 py-[6px] bg-[#FF553329] text-xs font-semibold text-[#161616]">
                Save {savings}
              </div>
            )}
          </div>
        </div>
        <style global jsx>{`
          .dailyPickProdName {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .new-header-daily-pick {
            padding: 1rem !important;
          }
          .new-header-daily-pick .mb-4 {
            margin-bottom: 1rem !important;
          }
          .new-header-daily-pick .ant-rate .ant-rate-star:not(:last-child) {
            margin-inline-end: 2px;
          }
          .countDownDate .ant-statistic-content-value {
            color: #1d862c;
            font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
          }
        `}</style>
      </div>
    )
  }
  return null
}

export default NewHeaderDailyPick
