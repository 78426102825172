import { useResponsive } from 'ahooks'
import { useEffect, useMemo, useState } from 'react'

export interface ResponsiveBreakpoints {
  isBiggerThanXs: boolean
  isBiggerThanSm: boolean
  isBiggerThanMd: boolean
  isBiggerThanLg: boolean
  isBiggerThanXl: boolean
  isBiggerThan2Xl: boolean
  isBiggerThanMiddle: boolean
  isBiggerThanLegacy: boolean
  isBiggerThanLarge: boolean
}

export const useResponsiveBreakpoint = (): ResponsiveBreakpoints => {
  const [isClient, setIsClient] = useState(false)
  const responsive = useResponsive()

  useEffect(() => {
    setIsClient(true)
  }, [])

  return useMemo(
    () => ({
      isBiggerThanXs: isClient ? (responsive?.xs ?? false) : false,
      isBiggerThanSm: isClient ? (responsive?.sm ?? false) : false,
      isBiggerThanMd: isClient ? (responsive?.md ?? false) : false,
      isBiggerThanLg: isClient ? (responsive?.lg ?? false) : false,
      isBiggerThanXl: isClient ? (responsive?.xl ?? false) : false,
      isBiggerThan2Xl: isClient ? (responsive?.['2xl'] ?? false) : false,
      isBiggerThanMiddle: isClient ? (responsive?.middle ?? false) : false,
      isBiggerThanLegacy: isClient ? (responsive?.legacy ?? false) : false,
      isBiggerThanLarge: isClient ? (responsive?.large ?? false) : false
    }),
    [responsive, isClient]
  )
}
