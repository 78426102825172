import { QUERY_KEY_HEADER_USER_INFO } from '@gc/common-lib/constants/query/header'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import { QUERY_KEY_MINI_CART } from '../../constants/query/cart'

export const useCartSync = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const handleCartUpdate = () => {
      try {
        void queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_HEADER_USER_INFO]
        })
        void queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MINI_CART] })
      } catch (error) {
        console.error('Mini Cart sync error:', error)
      }
    }

    const win = window as typeof window & { updateGCHeaderCart?: () => void }
    win.updateGCHeaderCart = handleCartUpdate
    window.addEventListener('gc:mini-cart:updated', handleCartUpdate)

    return () => {
      delete win.updateGCHeaderCart
      window.removeEventListener('gc:mini-cart:updated', handleCartUpdate)
    }
  }, [queryClient])
}
