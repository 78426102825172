import type { INewSubNavigation } from '@gc/common-lib/api/header'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { useGlobalHeaderMenuData } from '@hooks/global/new-header'
import { useResponsiveBreakpoint } from '@hooks/global/useResponsiveBreakpoint'
import { useClickAway } from 'ahooks'
import classNames from 'classnames'
import { filter, get, toLower } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import NewHeaderRightNav from './NewHeaderRightNav'
import ResponsiveMenu from './NewResponsiveMenu'
import SubCategoryModalContent from './sub-category-panel/SubCategoryModalContent'

const NewHeaderCategories: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const { data: headerMenu } = useGlobalHeaderMenuData()
  const [currentCategoryName, setCurrentCategoryName] = useState('')
  const { isBiggerThanLegacy } = useResponsiveBreakpoint()

  const [hiddenMenu, setHiddenMenu] = useState<INewSubNavigation[]>([])
  const [showHiddenMenu, setShowHiddenMenu] = useState(false)
  const [topOffset, setTopOffset] = useState(110)
  useClickAway(e => {
    if (
      !(e.target as Element).closest('.new-header-bottom') &&
      isBiggerThanLegacy
    ) {
      closePanel()
    }
  }, ref.current)

  useEffect(() => {
    const element = document.querySelector('#newHeaderBottom')
    if (!element || isBiggerThanLegacy) return

    const calculateOffset = () => {
      const newTopOffset = element.getBoundingClientRect().top ?? 0
      setTopOffset(newTopOffset + 44)
    }

    const resizeObserver = new ResizeObserver(() => {
      calculateOffset()
    })

    resizeObserver.observe(element)
    calculateOffset()

    return () => {
      resizeObserver.disconnect()
    }
  }, [isBiggerThanLegacy])

  if (!isArrayAndNotEmpty(headerMenu)) {
    return null
  }

  const categoryDataArr = filter(headerMenu, [
    'parentMediaName',
    'Department Menus'
  ])
  const subNavigation = get(categoryDataArr?.[0], 'subNavigation')

  if (!isArrayAndNotEmpty(subNavigation)) return null

  const featureDataArr = filter(headerMenu, [
    'parentMediaName',
    'Featured Menus'
  ])
  const featuredCategories = get(featureDataArr?.[0], 'subNavigation') || []

  const scrollContainerToTop = () => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
      }, 100)
    }
  }

  const closePanel = () => {
    setCurrentCategoryName('')
    document.body.classList.remove('overlay-and-overflow-hidden')
  }

  const setTemplateAndVisibilityForPanel = (
    childNavigation: INewSubNavigation,
    currentName: string
  ) => {
    setCurrentCategoryName(toLower(currentName))
    setShowHiddenMenu(toLower(childNavigation.childItemName) === 'more')
    if (toLower(currentName) !== '') {
      document.body.classList.add('overlay-and-overflow-hidden')
    } else {
      document.body.classList.remove('overlay-and-overflow-hidden')
    }
  }

  return (
    <>
      <div
        ref={ref}
        id="newHeaderBottom"
        className="relative z-[102] new-header-bottom bg-white legacy:!bg-[#F5F5F5] shadow-[0px_4px_4px_0px_#00000005]"
      >
        <div className="max-w-[1540px] w-full h-[44px] legacy:h-12 px-4 md:!px-6 mx-auto mb-0.5 legacy:mb-0 flex gap-8 justify-between items-center border-b border-solid border-[#EEEEEE] legacy:border-none whitespace-nowrap">
          <ResponsiveMenu
            menuItems={subNavigation}
            currentCategoryName={currentCategoryName}
            setTemplateAndVisibilityForPanel={setTemplateAndVisibilityForPanel}
            setHiddenMenu={setHiddenMenu}
            closePanel={closePanel}
            scrollContainerToTop={scrollContainerToTop}
          />
          <NewHeaderRightNav />
        </div>

        <div
          ref={scrollContainerRef}
          className={classNames(
            'menu-modal absolute z-[102] top-full w-full justify-center bg-white legacy:!bg-[#F9F9F9] h-[fit-content] overflow-y-auto',
            'max-h-[calc(100vh-var(--top-offset))] legacy:max-h-none',
            currentCategoryName === '' ? 'element-hidden' : 'flex active'
          )}
          style={{ '--top-offset': `${topOffset}px` } as React.CSSProperties}
        >
          <div className="max-w-[1540px] w-full bg-white legacy:!bg-[#F9F9F9] relative">
            <SubCategoryModalContent
              activeCategoryName={currentCategoryName}
              hiddenMenu={hiddenMenu}
              menus={subNavigation}
              showHiddenMenu={showHiddenMenu}
              featuredCategories={featuredCategories}
              closePanel={closePanel}
              scrollContainerToTop={scrollContainerToTop}
            />
          </div>
        </div>
        <style jsx global>
          {`
            .new-header-bottom {
              & > .px-4 {
                @media (max-width: 975.9px) {
                  padding-left: 1rem !important;
                  padding-right: 1rem !important;
                }
              }
            }
          `}
        </style>
      </div>
    </>
  )
}

export default withErrorBoundary(NewHeaderCategories)
