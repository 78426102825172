import { GC_DRAWER_HEADER_MINI_CART } from '@constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { useCartSync } from '@gc/common-lib/hooks/header/useCartSync'
import { isBrowser, readCookie } from '@gc/common-lib/utils/browser'
import Image from '@gc/ui-components/image'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import { useGlobalHeaderIconsAndTextData } from '@hooks/global/new-header'
import dynamic from 'next/dynamic'

const MiniCartModal = dynamic(
  () => import('@bc/algolia-plp/header/modal/MiniCartModal'),
  { ssr: false }
)

const NewHeaderMiniCart: React.FC = () => {
  useCartSync()
  const { changeDrawer, judgeIsDisplayDrawer } = useChangeDrawerArr()
  const { data: HeaderIconsAndText } = useGlobalHeaderIconsAndTextData()
  const { lineCount = -1 } = useHeaderUserInfo()
  const {
    emptyImgSrc = '/assets/images/new-header/icon-cart-empty.svg',
    filledAltText = 'filled mini cart icon',
    filledImgSrc = '/assets/images/new-header/icon-cart-filled.svg',
    emptyAltText = 'empty mini cart icon'
  } = HeaderIconsAndText?.miniCart ?? {}

  // get ab_enableHeaderMiniCartDirectlyToCart value
  const isEnableHeaderMiniCartDirectlyToCart = isBrowser()
    ? readCookie('ab_enableHeaderMiniCartDirectlyToCart') === 'true'
    : false
  return (
    <>
      <button
        onClick={() => {
          if (isEnableHeaderMiniCartDirectlyToCart) {
            window.location.href = '/cart'
          } else {
            changeDrawer(GC_DRAWER_HEADER_MINI_CART)
          }
        }}
        className="shrink-0 MiniCart flex flex-col justify-center w-11 h-11 legacy:w-[52px] legacy:h-[52px] ml-1 legacy:!ml-4  px-1 items-center text-sm text-center cursor-pointer hover:bg-[#F5F5F5] rounded"
      >
        <div className="miniCartIcon flex items-center relative">
          {lineCount > 0 ? (
            <span className="inline-block w-[21px] h-[21px]">
              <Image
                alt={filledAltText}
                src={filledImgSrc}
                width={21}
                height={21}
                className="mx-auto"
                priority={true}
              />
            </span>
          ) : (
            <Image
              alt={emptyAltText}
              src={emptyImgSrc}
              width={30}
              height={30}
              className="mx-auto"
              priority={true}
            />
          )}

          {lineCount > 0 && (
            <span className="miniCartTotal flex items-center justify-center min-w-4 h-4 absolute top-[-10px] right-[-10px] leading-[7px] bg-[#E7131A] text-white text-[10px] font-semibold rounded-2xl">
              <span className="miniCartCount inline-block h-[7px] -tracking-widest">
                {lineCount}
              </span>
            </span>
          )}
        </div>
      </button>
      {judgeIsDisplayDrawer(GC_DRAWER_HEADER_MINI_CART) ? (
        <MiniCartModal />
      ) : null}
      <style jsx>{`
        .miniCartCount {
          font-family: 'Acumin Pro', 'Roboto', arial, sans-serif;
        }
        @-moz-document url-prefix() {
          .miniCartCount {
            margin-left: -1px;
          }
        }
      `}</style>
    </>
  )
}

export default withErrorBoundary(NewHeaderMiniCart)
